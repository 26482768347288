
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import NotFoundCard from '@/components/cards/NotFoundCard.vue';
import ImageCard from "@/components/cards/ImageCard.vue";

import { Location } from '@/entities/Location';
import { Watch } from 'vue-property-decorator';
import { Restaurant } from '@/entities/restaurant/Restaurant';

@Component({
  components: {
    NotFoundCard,
    ImageCard
  },

  computed: {
    ...mapGetters([
      'location'
    ])
  }
})
export default class RestaurantsListview extends Vue {
  location!: Location;
  restaurants: Restaurant[] = [];

  mounted() {
    this.fetchRestaurants();
  }
  getRestaurantImageUrl(restaurant: Restaurant) {
    return restaurant.images?.find((image => image.category=='Logo'))?.file_url;
  }

  @Watch('location')
  async fetchRestaurants() {
    this.$restaurants.getByLocation(this.location).then(restaurants => {
      restaurants.forEach(element => {
        if (element.images&&element.images.length>0) {
          this.restaurants.push(element);
        }
      });
    });
  }
}
